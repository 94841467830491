import { useFormatter } from "helpers/i18n"

const NotFoundPage = () => {
  const { __ } = useFormatter()

  return (
    <div>
      <h1>{__({ defaultMessage: "404: Not Found" })}</h1>

      <p>
        {__({
          defaultMessage:
            "You just hit a route that doesn't exist... the sadness.",
        })}
      </p>
    </div>
  )
}

export default NotFoundPage
